import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'

import Navigation from '../components/Navigation'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Container from '@material-ui/core/Container';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
// import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import NotificationsIcon from '@material-ui/icons/Notifications';

import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import LinearProgress  from '@material-ui/core/LinearProgress';
// import Chip from '@material-ui/core/Chip';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCoffee } from '@fortawesome/free-solid-svg-icons'

import { makeStyles } from '@material-ui/core/styles';
import {getAlerts, updateAlert, deleteAlert} from '../utils/alertService.js'

const useStyles = makeStyles(theme => ({
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  fab: {
    // position: 'absolute',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  }
}));

// var initialState = {
//   alerts: []
// }
//
// var reducer = (state, action) => {
//   switch (action.type) {
//     case 'setAlerts':
//       return {alerts: action.payload.alerts};
//     default:
//       throw new Error();
//   }
// }


var Alerts = function() {

  // const preventDefault = (event) => event.preventDefault();
  const reloadPage = () => {window.location.reload()}

  const classes = useStyles();
  const history = useHistory();
  // const [state, dispatch] = useReducer(reducer, initialState);
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAdd = function() {
    history.push("/alerts/new")
  }

  const handleSwitch = async (event, alertId) => {
    var checked = event.target.checked
    await updateAlert(alertId, {enabled: checked})
    var getRes = await getAlerts()
    setAlerts(getRes)
  }

  const handleDelete = async (alertId) => {
    await deleteAlert(alertId)
    var getRes = await getAlerts()
    setAlerts(getRes)
  }

  useEffect(() => {
    // console.log(JSON.stringify(<FontAwesomeIcon icon={faCoffee} />))
    (async () => {
      setLoading(true)
      var res = await getAlerts();
      setAlerts(res)
      setLoading(false)
    })()
  }, []);

  const fab = {
    color: 'primary',
    className: classes.fab,
    icon: <AddIcon/>,
    label: 'Add'
  };

  const alertElement = (alert) => {
    var description

    if (alert.type === 'above') {
      description = `${alert.marketName} > ${alert.limit}`
    } else if (alert.type === 'below') {
      description = `${alert.marketName} < ${alert.limit}`
    } else if (alert.type === 'gainsOrLoses') {
      description = `${alert.marketName} -/+ ${alert.limit}% ${alert.recurring
        ? 'R'
        : ''}`
    } else if (alert.type === 'gains') {
      description = `${alert.marketName} + ${alert.limit}% ${alert.recurring
        ? 'R'
        : ''}`
    } else if (alert.type === 'loses') {
      description = `${alert.marketName} - ${alert.limit}% ${alert.recurring
        ? 'R'
        : ''}`
    } else {
      console.log(`unknown alert type: ${alert.type}`)
    }

     return (
        <ListItem key={alert._id} divider={true}>
          <ListItemText primary={ description } />
            <ListItemSecondaryAction>
              <IconButton edge="end" onClick={(event)=>{handleDelete(alert._id)}}>
                <DeleteIcon />
              </IconButton>
              <Switch
                edge="end"
                onChange={(event)=>{handleSwitch(event, alert._id)}}
                color="primary"
                checked={alert.enabled}
                value={alert.enabled ? 'checked' : false}
              />

            </ListItemSecondaryAction>
        </ListItem>
     )
  }


  return (
    <div>
      <Navigation title='My alerts'/>
      {  loading && (<LinearProgress  />)  }

      <Container maxWidth="sm">

        {(alerts.length === 0) ?
          !loading && (<React.Fragment> <br/> <p>You don't have any alert yet. Click the (+) button below to add one.</p> </React.Fragment>) :
          (

            <div className={classes.demo}>
              <List dense={false}>
                {alerts.map(alertElement)}
              </List>
            </div>

          )}

        <br/>

        <Box display="flex" justifyContent="center">
          <Link href="#" onClick={reloadPage}>
           <RefreshIcon fontSize="large"/>
          </Link>
        </Box>



        <Fab aria-label={fab.label} className={fab.className} color={fab.color} onClick={handleAdd}>
          {fab.icon}
        </Fab>
      </Container>
    </div>
    )

}

export default Alerts;
