import axios from 'axios';
import {
  LOGIN_URL
} from './urls'
import {
  clientInfo
} from './clientInfo'
// import {logout} from '../utils/userService';
// import React from 'react';
// import {UserContext} from '../utils/UserContext';


const backend = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL || undefined
  // timeout: 1000,
  // headers: {
  //   'X-Custom-Header': 'foobar'
  // }
});

// send authentication header in requests
backend.interceptors.request.use(function(config) {
  const token = localStorage.getItem('token')
  if (token)
    config.headers['Authorization'] = `Bearer ${token}`;
  if (clientInfo.version)
    config.headers['X-Client-version'] = `${clientInfo.version}`;

  return config;

}, function(err) {
  return Promise.reject(err);
});


// should do something in case of api authentication problems
backend.interceptors.response.use(function(response) {

  // reload the client bundle if there is a newer version
  if (response.headers['x-client-new-version']) {
    console.log('interceptor: new client version (res): ' + response.headers['x-client-new-version'])
    window.location.reload(true);
    return response
  }

  return response;
}, function(err) {

  // reload the client bundle if there is a newer version
  if (err.response.headers['x-new-client-version']) {
    console.log('interceptor: new client version (err)')
    window.location.reload(true);
    return Promise.reject(err);
  }

  // in case of 401 logs out and redirects to login page
  if (err.response.status === 401) {
    console.log('interceptor: unauthorized: loggin out')

      localStorage.removeItem('username');
      localStorage.removeItem('token');
      window.location.replace(LOGIN_URL)
      return Promise.reject(err);
  }

  return Promise.reject(err);
});

export default backend;
