import React from 'react';
import './App.css';
import {Route, Switch, BrowserRouter as Router} from 'react-router-dom'
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactGA from 'react-ga';

// import Login from './pages/login'
import Home from './pages/home'
import Faq from './pages/faq'
import Markets from './pages/markets'
import Alerts from './pages/alerts'
import TokenLogin from './pages/tokenLogin'
import Alert from './pages/alert'
import RequireAuth from './components/RequireAuth'
import {HOME_URL, ALERTS_URL, MARKETS_URL, LOGIN_URL, FAQ_URL} from './utils/urls'

import {UserContextProvider} from './utils/UserContext';

// <RequireAuth>
//   <Route exact={true} path="/alerts/:id" component={Alert}/>
// </RequireAuth>

const routing = (
  <React.Fragment>
    <CssBaseline />
    <UserContextProvider>
      <Router>
        <Switch>
          <Route exact={true} path={LOGIN_URL} component={TokenLogin}/>
          <Route exact={true} path={MARKETS_URL} component={Markets}/>          
          <Route exact={true} path="/login/:loginToken" component={TokenLogin}/>
            <RequireAuth>
              <Route exact={true} path={HOME_URL} component={Home}/>
              <Route exact={true} path="/alerts/:id" component={Alert}/>
              <Route exact={true} path={ALERTS_URL} component={Alerts}/>
              <Route exact={true} path={FAQ_URL} component={Faq}/>
            </RequireAuth>
        </Switch>
      </Router>
    </UserContextProvider>
  </React.Fragment>
)

// google analytics
const trackingId = "UA-154763560-1";
ReactGA.initialize(trackingId);
ReactGA.pageview('/fake');

function App() {
  return routing;
}

export default App;
