// import axios from 'axios';
import backend from './backend';

export async function getAlerts() {
  var res
  try {
    res = await backend.get('/api/alerts/')
    // console.log(res.data);

    // updates the state
    // dispatch({
    //   type: 'setAlerts',
    //   payload: {
    //     alerts: res.data
    //   }
    // })

    return res.data;
  } catch (err) {
    console.log('getAlerts error: ' + err)
  }
}

export async function createAlert(alert) {
  backend.post('/api/alerts/', alert).then(function(res) {
    // console.log(res.data);

    return res.data;
  }).catch(err => {
    console.log('getAlerts error: ' + err)
    return undefined;
  })
}

export async function updateAlert(alertId, body) {
  var res
  try {
    res = await backend.patch(`/api/alerts/${alertId}`, body)
    // console.log(res.data);

    return res.data;
  } catch (err) {
    console.log('updateAlert error: ' + err)
  }
}

export async function deleteAlert(alertId) {
  var res
  try {
    res = await backend.delete(`/api/alerts/${alertId}`)
    // console.log(res.data);

    return res.data;
  } catch (err) {
    console.log('deleteAlert error: ' + err)
  }
}
