// import axios from 'axios';
import backend from './backend';

export async function getMarkets(params) {
  try {
    var res = await backend.get('/api/markets/', {params: params})
    // console.log(`params: ${JSON.stringify(params)}`);
    console.log(`received markets: ${res.data.length}`);
    return res.data;
  } catch (err) {
    console.log('getMarkets error: ' + err)
    return;
  }
}
