import React from "react";

import {
  Redirect
} from "react-router-dom";

import {
  UserContext
} from '../utils/UserContext';

import {LOGIN_URL} from '../utils/urls'


var RequireAuth = function({
  children
}) {
  const {
    state: userState
  } = React.useContext(UserContext);

  if (!userState.isAuthenticated) {
    console.log('RequireAuth: login redirect')
    return <Redirect to={LOGIN_URL} / > ;
  }

  return children;
};

export default RequireAuth;
