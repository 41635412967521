// import axios from 'axios';
import backend from './backend';

export async function tokenLogin(loginToken, userDispatch) {
  var res
  try {
    res = await backend.post('/api/users/token-login', {
      loginToken: loginToken
    })
  } catch (err) {
    console.log(err);
  }

  console.log(res.data);
  var userName = res.data.user.tgFirstName || res.data.tgUsername || res.data.user.tgId
  localStorage.setItem('username', userName);
  localStorage.setItem('token', res.data.token);

  // updates the state
  userDispatch({
    type: 'login',
    payload: {
      userName: userName
    }
  })
  return res.data
}

export async function loginMock(tgResponse, userDispatch) {
  var res
  try {
    res = await backend.post('/api/users/login-mock', {
      telegram: tgResponse
    })
  } catch (err) {
    console.log(err);
  }

  console.log(res.data);
  var userName = res.data.user.tgFirstName || res.data.tgUsername || res.data.user.tgId
  localStorage.setItem('username', userName);
  localStorage.setItem('token', res.data.token);

  // updates the state
  userDispatch({
    type: 'login',
    payload: {
      userName: userName
    }
  })
  return res.data
}

export async function login(tgResponse, userDispatch) {
  var res
  try {
    res = await backend.post('/api/users/login', {
      telegram: tgResponse
    })
  } catch (err) {
    console.log(err);
  }
  console.log(res.data);

  var userName = res.data.user.tgFirstName || res.data.tgUsername || res.data.user.tgId
  localStorage.setItem('username', userName);
  localStorage.setItem('token', res.data.token);

  // updates the state
  userDispatch({
    type: 'login',
    payload: {
      userName: userName
    }
  })
  return res.data
}

export async function logout(userDispatch) {
  localStorage.removeItem('username');
  localStorage.removeItem('token');
  try {
    await backend.post('/api/users/logout')
  } catch (err) {
    console.log(err);
  }
  console.log('logout');

  // updates the state
  if (userDispatch) {
    userDispatch({
      type: 'logout'
    })
  }

}
