import React, {createContext,  useReducer} from 'react';

const UserContext = createContext();

let initialState = {
  isAuthenticated: localStorage.getItem('username') ? true : false,
  userName: localStorage.getItem('username')
};

let reducer = (state, action) => {
  switch (action.type) {
    case 'login':
      return {
        isAuthenticated: true,
        userName: action.payload.userName
      };
    case 'logout':
      return {
        isAuthenticated: false,
        userName: undefined
      };
    default:
      throw new Error();
  }
}

function UserContextProvider(props) {

  let [state, dispatch] = useReducer(reducer, initialState);
  let value = { state, dispatch };

  return (
    <UserContext.Provider value={value}>{props.children}</UserContext.Provider>
  );
}

let UserContextConsumer = UserContext.Consumer;

export { UserContext, UserContextProvider, UserContextConsumer };
