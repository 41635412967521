import React, {useReducer, useEffect} from 'react';

import Navigation from '../components/Navigation'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Container from '@material-ui/core/Container';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import NotificationsIcon from '@material-ui/icons/Notifications';
// import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import {getMarkets} from '../utils/marketService.js'


const useStyles = makeStyles(theme => ({
  demo: {
    backgroundColor: theme.palette.background.paper
  }
}));

var initialState = {
  markets: []
}

var reducer = (state, action) => {
  switch (action.type) {
    case 'setMarkets':
      return {markets: action.payload.markets};
    default:
      throw new Error();
  }
}

var marketElement = (market) => {
   return (
      <ListItem key={market._id}>
        <ListItemIcon>
           <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={market.name} />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="Alert">
              <NotificationsIcon />
            </IconButton>
          </ListItemSecondaryAction>
      </ListItem>
   )
}

var Markets = function() {

  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    getMarkets()
    .then((markets)=> {
      // updates the state
      dispatch({
        type: 'setMarkets',
        payload: {
          markets: markets
        }
      })
    })
    .catch(()=> {console.log('Error while getting markets')})

  }, []);


  return (
    <div>
      <Navigation/>

      <Container maxWidth="sm">

        <div className={classes.demo}>
          <List dense={false}>
            {state.markets.map(marketElement)}
          </List>
        </div>
      </Container>
    </div>
    )

}

export default Markets;
