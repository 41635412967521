import React from 'react';

import Navigation from '../components/Navigation'
import {useHistory} from 'react-router-dom'


var Home = function () {

  const history = useHistory();
  history.push("/alerts");

    return (
      <div>
        <Navigation />
        <p> Home </p>
      </div>

    );
}

export default Home;
