import React, {useEffect, useRef} from 'react';
import {useHistory} from 'react-router-dom'
import clsx from 'clsx';
import ReactGA from 'react-ga';

import Navigation from '../components/Navigation'
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
// import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';

// import FormHelperText from '@material-ui/core/FormHelperText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import Divider from '@material-ui/core/Divider';
// import SearchIcon from '@material-ui/icons/Search';

import { makeStyles } from '@material-ui/core/styles';
import {getMarkets} from '../utils/marketService.js'
import {createAlert} from '../utils/alertService.js'
// const _ = require('lodash');

const useStyles = makeStyles(theme => ({
  demo: {
    backgroundColor: theme.palette.background.paper
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    width: 200,
  },
  frm: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200
    }
  }
}));


var Alert = function() {

    const classes = useStyles();
    const history = useHistory();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState([]);

    const [market, setMarket] = React.useState(undefined);
    const [condition, setCondition] = React.useState('gainsOrLoses');

    const [unit, setUnit] = React.useState('%');
    const [limit, setLimit] = React.useState('5');
    const [frequency, setFrequency] = React.useState('recurring');

    // const loading = open && options.length === 0;
    var loading = false;

    // const rnd = function(n) {
    //   return parseFloat(n.toPrecision(7), 10)
    // }

    const handleConditionChange = event => {
      const val = event.target.value
      setCondition(val);
      if (val === 'above' || val === 'below') {
        setLimit(market.price)
        setUnit(market.quote)
        setFrequency('once')
      }
      else {
        setLimit(5)
        setUnit('%')
        setFrequency('recurring')
      }
    };

    const handleLimitChange = event => {
      const val = event.target.value
      setLimit(val);
    };

    const handleFrequencyChange = event => {
      const val = event.target.value
      setFrequency(val);
    };

    const handleMarketAutocompleteChange = event => {
      // console.log('onChange: ' + event.target.textContent);
      // console.log(event.target);
      _setMarket(event.target.textContent);

    }

    const handleSubmit = async () => {
      await createAlert({market: market._id, type: condition, limit: limit, frequency: frequency})

      ReactGA.event({
        category: 'Alerts',
        action: 'Added alert',
        label: `${market.name} - ${market.source} - ${condition}`
      });

      history.push("/alerts")
    };

    const fetchMarkets = async (text) => {
      loading = true;
      const markets = await getMarkets({text: text})
      console.log(`mark: ${markets.length}`)

    //  if (active) {
        setOptions(markets.map((m) => {
          return m
        }

      ));
      loading = false;
    //  }
    }

    const _setMarket = function(name) {
      if (name) {
        // var selected = _.find(options, ['name', name]);
        var selected = options.find((option) => {return name ===`${option.name} - ${option.source}`})
        setMarket(selected);
      } else {
        setMarket(undefined);
      }
    }

    const limitRef = useRef()
    const setAlertRef = useRef()

    const handleEnter = (e) => {
     if (e.key === 'Enter') {
       setAlertRef.current.focus()
     }}

    useEffect(() => {
      if (!open) {
        setOptions([]);

        // move the focus on "limit" input once the asset is selected
        limitRef.current.focus()
      }
    }, [open]);


  return (
    <div>
      <Navigation title='New alert' />

      <Container maxWidth="sm">
        <br/>

        <FormLabel component="legend" className={classes.margin}>Pair/Asset</FormLabel>

        <Autocomplete
          className={classes.margin}
          id="market-autocomplete"
          noOptionsText="🔎 Enter pair/asset ticker"
          style={{  }}
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionLabel={option => `${option.name} - ${option.source}`}
          options={options}
          filterOptions={(options, state) => {return options}}
          loading={loading}
          onInputChange={(event,text) => {fetchMarkets(text);}}
          onChange={(event,text) => {handleMarketAutocompleteChange(event);}}
          renderInput={params => (
            <TextField
              {...params}
              label=""
              fullWidth
              autoFocus
              placeholder="BTC/USD"
              variant="standard"
              helperText={market ? `Current price: ${market.price}` : undefined}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

      <br/><br/>

        <FormLabel component="legend" className={classes.margin}>Condition</FormLabel>

        <form style={{ display: 'flex' }} className={classes.frm} noValidate autoComplete="off" onSubmit={e => { e.preventDefault(); }}>

        <FormControl className={classes.formControl}>
          <Select
            labelId="condition-select-label"
            id="condition-select"
            value={condition}
            onChange={handleConditionChange}
            disabled={!market}
          >
          <MenuItem value={'above'}>Moves above</MenuItem>
          <MenuItem value={'below'}>Moves below</MenuItem>
          <MenuItem value={'gainsOrLoses'}>Gains / Loses</MenuItem>
          <MenuItem value={'gains'}>Gains</MenuItem>
          <MenuItem value={'loses'}>Loses</MenuItem>
          </Select>
        </FormControl>



        <FormControl className={clsx(classes.margin, classes.textField)} variant="filled">
          <Input
            id="limit-input"
            type="number"
            disabled={!market}
            error = {frequency==='recurring' && limit <= 2}
            label="Standard"
            value={limit}
            onChange={handleLimitChange}
            endAdornment={<InputAdornment position="end">{unit}</InputAdornment>}
            aria-describedby="filled-weight-helper-text"
            inputProps={{
              'aria-label': 'weight',
              'ref': limitRef,
              'onKeyPress': handleEnter
            }}
          />
        </FormControl>

        </form>

        <br/><br/>

        <FormControl component="fieldset" className={classes.margin}>
          <FormLabel component="legend">Frequency</FormLabel>
          <RadioGroup style={{ display: 'flex' }} aria-label="frequency" name="frequency" value={frequency} onChange={handleFrequencyChange} row>
            <FormControlLabel
              value="once"
              control={<Radio color="primary" />}
              label="Once"
              disabled={!market || (condition === 'above' || condition === 'below')}
              labelPlacement="end"
            />
            <FormControlLabel
              value="recurring"
              control={<Radio color="primary" />}
              label="Recurring"
              disabled={(condition === 'above' || condition === 'below')}
              labelPlacement="end"
            />
          </RadioGroup>
        </FormControl>

        <br/><br/>

        <FormControl component="fieldset" className={classes.margin}>
          <FormLabel component="legend">Notification type</FormLabel>
          <FormControlLabel disabled control={<Checkbox checked value={true} />} label="Telegram" />
        </FormControl>

        <br/><br/>

        <Grid container alignItems="flex-start" justify="flex-end" direction="row">
          <Button ref={setAlertRef} variant="contained" color="primary" disabled={!market || !limit || (frequency==='recurring' && limit <= 2)} onClick={handleSubmit}>
            Set Alert
          </Button>
        </Grid>

      </Container>
    </div>
    )

}

export default Alert;
