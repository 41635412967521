import React from 'react';

import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

import Navigation from '../components/Navigation'
// import {useHistory} from 'react-router-dom'


var Faq = function () {

    return (
      <div>
        <Navigation title="FAQ"/>
          <Container maxWidth="sm">
            <Box fontWeight="fontWeightBold">
              <p fontWeight="fontWeightBold"> Q: How does this thing work? </p>
            </Box>
            <p> A: Just add price alerts for your favourite pairs/assets using the (+) button. You can see a list of your current alerts on the alert page. Once the price hits the alert limit you will receive a notification on telegram. </p>

            <br/>
            <Box fontWeight="fontWeightBold">
              <p> Q: I can't see my alerts on the alert page </p>
            </Box>
            <p> A: Try to refresh the page </p>

            <br/>

            <p>Info, suggestions, feedbacks: <Link href="https://t.me/aristide_piazza"> https://t.me/aristide_piazza </Link></p>


          </Container>

      </div>

    );
}

export default Faq;
