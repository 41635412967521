import React from 'react';
import {useHistory} from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
// import Switch from '@material-ui/core/Switch';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import FormGroup from '@material-ui/core/FormGroup';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Button from '@material-ui/core/Button';

// import axios from 'axios';
// import localProfile from '../utils/localProfile';
import {logout} from '../utils/userService';
import {UserContext} from '../utils/UserContext';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));


var Navigation = function(props) {
  // const history = useHistory();

const {state: userState, dispatch: userDispatch} = React.useContext(UserContext);

  var history = useHistory();

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const title = props.title || 'Market alerts'

  // const handleChange = event => {
  //   // setAuth(event.target.checked);
  // };

  const handleMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={ () => {history.push("/");} }>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {
            (userState.isAuthenticated) ?
            (
              <div>
                 <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={ handleMenu } color="inherit" >
                   <AccountCircle />
                 </IconButton>
                 <Menu id="menu-appbar" anchorEl={anchorEl} anchorOrigin={{ vertical: 'top', horizontal: 'right', }} keepMounted transformOrigin={{ vertical: 'top', horizontal: 'right', }} open={open} onClose={ handleClose } >
                   { process.env.NODE_ENV==='development' && ( <MenuItem onClick={ () => {history.push("/"); handleClose();} }>Home</MenuItem> ) }
                   { process.env.NODE_ENV==='development' && ( <MenuItem onClick={ () => {history.push("/markets"); handleClose();} }>Markets</MenuItem> ) }
                   { process.env.NODE_ENV==='development' && ( <MenuItem onClick={ () => {history.push("/alerts"); handleClose();} }>Alerts</MenuItem> ) }
                   { process.env.NODE_ENV==='development' && ( <MenuItem onClick={ () => {history.push("/alerts/new"); handleClose();} }>Alert</MenuItem> ) }
                   <MenuItem onClick={ () => {history.push("/faq"); handleClose();} }>FAQ</MenuItem>
                   <MenuItem onClick={ () => {logout(userDispatch); handleClose();} }>Logout</MenuItem>
                 </Menu>
              </div>
            ) :
            ( false && <Button color="inherit" onClick={ () => {history.push("/login");} }> Login </Button> )
          }
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default Navigation;
