import React, {useEffect} from 'react';

import Navigation from '../components/Navigation'
import {useHistory, useParams} from 'react-router-dom'
import {UserContext} from '../utils/UserContext';
import {tokenLogin} from '../utils/userService';

import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import TelegramIcon from '@material-ui/icons/Telegram';
import { makeStyles } from '@material-ui/core/styles';


var TokenLogin = function() {

  const history = useHistory();
  // history.push("/alerts");
  const {loginToken} = useParams();

  const {state: userState, dispatch: userDispatch} = React.useContext(UserContext);

  const goToNextPage = () => {
    history.push("/alerts")
  }

  const loginAndRedirect = async () => {
    // console.log('A')
    if (userState.isAuthenticated) {
      // console.log('B')
      goToNextPage()
    } else if (loginToken) {
      // console.log('C')
      await tokenLogin(loginToken, userDispatch)
      // console.log('D')
      if (userState.isAuthenticated) {
        // console.log('E')
        goToNextPage()
      } else {
        console.log('TokenLogin: error')
      }
    }
  }

  useEffect(() => {
    loginAndRedirect()
  })

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1)
    }
  }));

  const classes = useStyles();

  const goToBot = () => {
    window.open(`https://t.me/${process.env.REACT_APP_BOT_NAME || "MarketMonBot"}?start=a`)
  }

    return (
      <div>
        <Navigation />

          <br/><br/><br/>
          <Grid container justify="center" alignItems="center" spacing={3}>
            <Grid item xs style={{textAlign: "center"}}>
              <Button variant="outlined" color="primary" className={classes.button} endIcon={<TelegramIcon>send</TelegramIcon>} onClick={() => {goToBot()}}>
              Login with Telegram
              </Button>
              <br/><br/>
              <Link href="https://telegram.org/" target="_blank" rel="noopener"> Don't have Telegram?</Link>
            </Grid>
          </Grid>
      </div>
    );
}

export default TokenLogin;
